import Holiday from "../../../../services/doctor-holiday-service"

export default {
    mapperInitialData (result) {
        return {
            date: result && result.date ? result.date : '',
            ...(result.jamMulai ?  {jamMulai: result.jamMulai} : {}),
            ...(result.jamSelesai ?  {jamSelesai: result.jamSelesai} : {}),
            ...(result.dokter ? {
                dokterId: result.dokter.id,
                dokterNama: result.dokter.nama,
                isHaveTelekonsul: result.dokter.hasOwnProperty('tarif'),
                ...(result.dokter.hasOwnProperty('tarif') ? {
                    tarif: result.dokter.tarif.jumlah,
                    price: result.dokter.tarif.jumlah
                } : {})
            } : {})
        }
    },
    mapperGetReservasi(data, dataDefault) {
        let isHaveTelekonsul = data.dokter.hasOwnProperty('tarif')
        let isHaveTarif = data.telekonsul
        return {
            ...dataDefault,
            generatedId: data.generatedId,
            gender: data.gender,
            jamMulai: data.jamMulai ? data.jamMulai.slice(0, 5) : '',
            jamSelesai: data.jamSelesai ? data.jamSelesai.slice(0, 5) : '',
            id: data.id,
            namaPasien: data.namaPasien,
            nomorMedicalRecord: data.nomorMedicalRecord,
            dokterId: data.dokter.id,
            dokterNama: data.dokter.nama,
            isHaveTelekonsul,
            ...(isHaveTelekonsul ? {tarif: data.dokter.tarif, price: data.dokter.tarif} : {}),
            ...(isHaveTarif ? {price: data.price} : {}),
            isModeTelekonsul: isHaveTarif,
            patientId: data.patiendId,
            tanggalLahir: data.tanggalLahir,
            noTelp: data.telp,
            email: data.email,
            penjamin: data.jenisPenjamin,
            pesan: data.pesan
        }
    },
    mapperParamSearch(item) {
        return {
            page: item.PAGE_ACTIVE - 1,
            size: item.PAGE_SIZE ?? 6,
            ...(item.SEARCH_NAMA ? {patientName: item.SEARCH_NAMA} : {}),
            ...(item.SEARCH_TANGGAL_LAHIR ? {dateOfBirth: item.SEARCH_TANGGAL_LAHIR} : {}),
            ...(item.SEARCH_TELP ? {mobilePhone1: item.SEARCH_TELP} : {})
        }
    },
    mapperListSearch(data) {
        return data.map(_item => {
            return {
                id: _item.id,
                nama: _item.patientName,
                tanggalLahir: _item.dateOfBirth,
                noTelp: _item.mobilePhone1,
                email: _item.email,
                jenisKelamin: _item.gender,
                noMedicalRecord: _item.medicalNo,
                isPasienHIS: _item.patient_type && _item.patient_type == "PATIENT_ORIGIN",
            }
        })
    },
    mapperListGender(list) {
        return list.map(_item => _item.initial)
    },
    // data diambil dari mapper get reservasi
    initContextStep1(data) {
        if(data.generatedId && data.patientId) {
            return {
                MODE: "DETAIL_PASIEN",
                SELECTED_PASIEN: {
                    id: data.patientId,
                    nama: data.namaPasien,
                    tanggalLahir: data.tanggalLahir,
                    noTelp: data.noTelp,
                    email: data.email,
                    jenisKelamin: data.gender,
                    noMedicalRecord: data.nomorMedicalRecord
                },
            }
        } else if (data.generatedId) {
            return {
                MODE: "FORM_PASIEN",
                BUTTON_FORM: "ENABLE",
                DIALOG_PHONE: false,
                IS_COMPONENT_COUNTRY: false,
                FORM: {
                    NAMA: data.namaPasien,
                    JENIS_KELAMIN: data.gender,
                    TANGGAL_LAHIR: data.tanggalLahir,
                    EMAIL: data.email,
                    NO_TELP: data.noTelp,
                    NOMOR_MRN: data.nomorMedicalRecord,
                    NAMA_STATUS: "SUCCESS",
                    JENIS_KELAMIN_STATUS: "SUCCESS",
                    TANGGAL_LAHIR_STATUS: "SUCCESS",
                    NO_TELP_STATUS: "SUCCESS",
                },
            }
        } else {
            return {
                MODE: "SEARCH_PASIEN",
                SEARCH_NAMA: "",
                SEARCH_TANGGAL_LAHIR: "",
                SEARCH_TELP: "",
                BUTTON_SEARCH_STATUS: "IDLE",
                LIST_SEARCH_PASIEN: [],
            }
        }
    },
    // validation text
    validationPhone(item) {
        return item.match(/^[0-9]{8,16}$/) ? true : false
    },
    validationRequired(item) {
        return !!item
    },
    //  content 2
    initContextStep2(data, isPermission) {
        // harus cek valid date
        const isSameOrAfter = this.checkDateSameOrBefore(data.date)
        if (data.generatedId || data.dokterId) {
            return {
                DATE: data.date,
                STATUS_DATE: isSameOrAfter,
                IS_SPESIALIS: false,
                MODE_KONSULTASI: data.isModeTelekonsul ? 'TELEKONSUL' : 'TATAP_MUKA',
                LIST_DOKTER: [],
                STATUS_LIST_DOKTER: "IDLE",
                PERMISSION_UPDATE_PRICE: isPermission,
                STATUS_SELECTED_DOKTER: 'SELECTED',
                DETAIL_PASIEN: {
                    SMS: data.noTelp,
                    EMAIL: data.email
                },
                SELECTED_DOKTER: [
                    {
                        id: data.dokterId,
                        nama: data.dokterNama,
                        isHaveTelekonsul: data.isHaveTelekonsul,
                        ...(data.tarif ? {tarif: data.tarif}: {})
                    },
                ],
                STATUS_SELECTED_SLOT: true,
                SELECTED_SLOT: {
                    id: data.dokterId,
                    nama: data.dokterNama,
                    jamMulai: data.jamMulai,
                    jamSelesai: data.jamSelesai,
                    ...(data.isHaveTelekonsul ? {price: data.price}: {})
                },
                SELECTED_SLOT_BEFORE: {
                    jamMulai: data.jamMulai,
                    jamSelesai: data.jamSelesai,
                }
            }
        }
        return {
            DATE: data.date,
            STATUS_DATE: isSameOrAfter,
            IS_SPESIALIS: false,
            MODE_KONSULTASI: "TATAP_MUKA",
            STATUS_SELECTED_DOKTER: "EMPTY",
            LIST_DOKTER: [],
            STATUS_LIST_DOKTER: "IDLE",
            PERMISSION_UPDATE_PRICE: isPermission,
            DETAIL_PASIEN: {
                SMS: '',
                EMAIL: ''
            }
        }
    },
    initContextStep3(data) {
        return {
            STATUS_PENJAMIN: data.penjamin ? true : false,
            SELECTED_PENJAMIN: data.penjamin ? {id: data.penjamin, nama: data.penjamin} : {},
            LIST_PENJAMIN: [],
            MESSAGE: data.pesan
        }
    },
    mapperObjDokter(_item) {
        return {
            id: _item.id,
            nama: _item.nama,
            isHaveTelekonsul: !!_item.tarif,
            ...(_item.tarif ? {tarif: _item.tarif.jumlah}: {}),
        }
    },
    mapperListAllDokter(list) {
        const duplicateList = JSON.parse(JSON.stringify(list))
        const listDokter = list.map(_item => this.mapperObjDokter(_item))
        const groupDokter = duplicateList.reduce((prev, item) => {
            let dokter = item
            dokter.tujuanRujukan = this.getDefaultSpesialisasi(dokter.dokterSpesialisasis)
            const idDokter = dokter.tujuanRujukan ? dokter.tujuanRujukan.id : ''
            if(idDokter) {
                const index = prev.findIndex(y => y.id == idDokter)
                if(index < 0) {
                    const poly = { ...dokter.tujuanRujukan };
                    const doctorsInPoli = this.mapperObjDokter(dokter)
                    poly.doctors = [doctorsInPoli]
                    prev.push(poly)
                } else {
                    prev[index].doctors.push(this.mapperObjDokter(dokter))
                }
            }
            return prev
        }, [])
        return {
            listWithSpesialis: groupDokter,
            listDokter
        }
    },
    getDefaultSpesialisasi(dokterSpesialisasi){
        let defaultSpesialisasi = null
            if (Array.isArray(dokterSpesialisasi)) {
                dokterSpesialisasi.forEach(item => {
                    if (item.isDefault || item.default) {
                        if (item.spesialisasi) {
                            defaultSpesialisasi = this.spesialisasiMapper(item.spesialisasi)
                        }
                    }
                })
            return defaultSpesialisasi
            }
    },
    spesialisasiMapper(spesialisasi){
        if(spesialisasi){
            return {
                id: spesialisasi.ID ? spesialisasi.ID : spesialisasi.id,
                nama: spesialisasi.namaIndo
            }
        }
    },
    slotDokterShow(list, beforeContext = []) {
        const listBefore = beforeContext.filter(_item => list.some(_item2 => _item && _item2.id == _item.id))
        const listNew = list.filter(_item => !beforeContext.some(_item2 => _item2 && _item2.id == _item.id))
                            .map(_item => {
                                return {
                                    id: _item.id,
                                    dokter: _item.nama,
                                    STATUS_JADWAL: false,
                                    isResult: false,
                                    IS_LOADING_RESERVASI: true,
                                    MODE_TELEKONSUL: _item.isHaveTelekonsul,
                                    ...(typeof(_item.tarif) == 'number' ? {PRICE: _item.tarif} : {}),
                                    jadwals: []
                                }
                            })
        const allList = [...listBefore, ...listNew]
        return allList
    },
    setJadwalToDokter(dokter, jadwals, {date, slotSelected}) {
        const cancelJadwalDokter = []
        jadwals
            .sort((a, b) => a.jadwal.jamMulai.localeCompare(b.jadwal.jamMulai))
            .forEach(item => {
                const dokterInJadwal = item.jadwal.dokter.id
                const indexDokter = dokter.findIndex(_item => _item.id == dokterInJadwal)
                if(!dokter[indexDokter].isResult) {
                    const textJadwal = `${item.jadwal.jamMulai} - ${item.jadwal.jamSelesai}`
                    const isSlotBlockedDay = item.jadwal.statusJadwalDokters.some(_item => _item && _item.tanggal == date && _item.status == 'CANCELLED')
                    if(isSlotBlockedDay) {
                        cancelJadwalDokter.push({dokterId: dokterInJadwal})
                    }
                    const isSlotBlocked = cancelJadwalDokter.some(_item => _item && _item.dokterId == dokterInJadwal)
                    const slots = this.setSlotInDokter(item.jadwal, date, isSlotBlocked)
                    const reserved = item.slotJadwals
                    const waitingList = item.queueWalkIns
                    const slotsWithReserved = this.setSlotwithReserved(reserved,waitingList, slots , slotSelected)
                    dokter[indexDokter].jadwals.push({
                        textJadwal,
                        slots: slotsWithReserved,
                    })
                    dokter[indexDokter].STATUS_JADWAL = true
                }
            })
        return dokter.map(_item => {
            return {
                ..._item,
                isResult: true,
                IS_LOADING_RESERVASI: false
            }
        })
    },
    mapperHoliday(holiday, dokter, date) {
        return dokter.map(_item => {
            let isHoliday = false
            let holidayDescription = ''

            if (holiday && Array.isArray(holiday) && holiday.length > 0) {
                const selectedDate = moment(date, 'YYYY-MM-DD')
                isHoliday = holiday.some(h => {
                    const startDate = moment(h.tanggalMulai)
                    const endDate = moment(h.tanggalSelesai)
                    return selectedDate.isSameOrAfter(startDate, 'day') &&
                           selectedDate.isSameOrBefore(endDate, 'day')
                })

                if (isHoliday) {
                    const matchedHoliday = holiday.find(h => {
                        const startDate = moment(h.tanggalMulai)
                        const endDate = moment(h.tanggalSelesai)
                        return selectedDate.isSameOrAfter(startDate, 'day') &&
                               selectedDate.isSameOrBefore(endDate, 'day')
                    })
                    holidayDescription = matchedHoliday ? matchedHoliday.description : ''
                }
            }

            return {
                ..._item,
                HOLIDAY: isHoliday,
                HOLIDAY_REMARK: holidayDescription
            }
        })
    },

    setSlotInDokter(jadwal, date, isCancelDay = false) {
        const totalSlot = this.countSlot(jadwal.jamMulai, jadwal.jamSelesai, jadwal.durationInMinute)
        let counterSlot = 1
        var startMoment = moment(`${date} ${jadwal.jamMulai}`,'YYYY-MM-DD HH:mm')
        const idDokter = jadwal.dokter.id
        const slotAll = []
        const slotIndexBlocked = jadwal.slotIndexBlocked
        while(counterSlot <= totalSlot) {
            var start = startMoment.clone();
            var end = startMoment.add(jadwal.durationInMinute, 'm');
            const jamMulai = moment(start).format('HH:mm')
            const jamSelesai = moment(end).format('HH:mm')
            const isBlocked = slotIndexBlocked.some(_item => _item == counterSlot - 1)
            const objSlot = {
                type: isBlocked || isCancelDay ? 'BLOCKED' : 'EMPTY',
                text: `${jamMulai} - ${jamSelesai} 0/${jadwal.personInSlot}`,
                value: {
                    id: idDokter,
                    jamMulai,
                    jamSelesai,
                    personInSlot: jadwal.personInSlot,
                    reserved: 0
                }
            }
            slotAll.push(objSlot)
            counterSlot++
        }
        const objWaiting = {
            type: isCancelDay ? 'BLOCKED' : 'EMPTY',
            text: `Waiting List 0`,
            value: {
                id: idDokter,
                jamMulai: '',
                jamSelesai: '',
                personInSlot: jadwal.personInSlot,
                reserved: 0
            }
        }
        slotAll.push(objWaiting)
        return slotAll
    },
    countSlot(mulai, selesai, durationSlot) {
        const jamMulai = mulai.split(":");
        const jamSelesai = selesai.split(":");
        const minuteJamMulai =
            parseFloat(jamMulai[0] * 60) + parseFloat(jamMulai[1]);
        const minuteJamSelesai =
            parseFloat(jamSelesai[0] * 60) + parseFloat(jamSelesai[1]);
        const durationMinute = minuteJamSelesai - minuteJamMulai;
        return Math.floor(durationMinute / durationSlot)
    },
    withEmptyReserved(selectedSlotBefore, selectedSlot, beforeType) {
        let type = beforeType
        if(selectedSlotBefore.jamMulai) {
            type = (selectedSlotBefore.jamMulai == selectedSlot.jamMulai && selectedSlotBefore.jamSelesai == selectedSlot.jamSelesai) ? 'EMPTY' : type
        }
        return type
    },
    setSlotwithReserved( reserved,waitingList, slot, slotBeforeSelected = {}) {
        reserved.forEach(_item => {
               const indexSlot = slot.findIndex(_item2 => _item2.value.jamMulai == _item.jamMulai)
               if(indexSlot >= 0) {
                 const countReserved = slot[indexSlot].value.reserved + 1
                 const type = countReserved >= slot[indexSlot].value.personInSlot ? 'RESERVED' : 'EMPTY'
                 slot[indexSlot].type = slot[indexSlot].type != 'BLOCKED' ? this.withEmptyReserved(slotBeforeSelected, slot[indexSlot].value, type) :'BLOCKED'
                 slot[indexSlot].text = `${slot[indexSlot].value.jamMulai} - ${slot[indexSlot].value.jamSelesai} ${countReserved}/${slot[indexSlot].value.personInSlot}`
                 slot[indexSlot].value.reserved = countReserved
               }
        })
        waitingList.forEach(_item => {
            const indexWaiting = slot.length - 1
            const countReserved = slot[indexWaiting].value.reserved + 1
            slot[indexWaiting].text = `Waiting List ${countReserved}`
            slot[indexWaiting].value.reserved = countReserved
        })
        return slot
    },
    checkDateSameOrBefore(date) {
        return moment().isSameOrBefore(date, 'day')
    },
    checkSlotFromChangeConsult({mode, selectedSlot, list_dokter}) {
        if(selectedSlot.id && mode == 'TELEKONSUL') {
            const index = list_dokter.findIndex(_item => _item.id == selectedSlot.id)
            if (list_dokter[index].MODE_TELEKONSUL) return selectedSlot
        } else if(selectedSlot.id) {
            return selectedSlot
        }
        return {}
    },
    payloadCreateReservasi(data, isHavePermission) {
        const detailPasien = data.MODE == 'DETAIL_PASIEN' ? {
            namaPasien: data.SELECTED_PASIEN.nama,
            tanggalLahir: data.SELECTED_PASIEN.tanggalLahir,
            telp: data.SELECTED_PASIEN.noTelp,
            gender: {initial : data.SELECTED_PASIEN.jenisKelamin},
            ...(data.SELECTED_PASIEN && data.SELECTED_PASIEN.noMedicalRecord ? {nomorMedicalRecord: data.SELECTED_PASIEN.noMedicalRecord} : {}),
            patient: {
                ...(data.SELECTED_PASIEN.id ? {id: data.SELECTED_PASIEN.id} : {}),
                "medicalNo": data.SELECTED_PASIEN.noMedicalRecord ?  data.SELECTED_PASIEN.noMedicalRecord : null,
                "email": data.SELECTED_PASIEN.email
            }
        } : {
            namaPasien: data.FORM.NAMA,
            tanggalLahir: data.FORM.TANGGAL_LAHIR,
            telp: data.FORM.NO_TELP,
            gender: {initial : data.FORM.JENIS_KELAMIN},
            patient: {
                "medicalNo": data.FORM.NOMOR_MRN ?  data.FORM.NOMOR_MRN : null,
                "email": data.FORM.EMAIL
            }
        }

        const modeTelekonsul = data.MODE_KONSULTASI == 'TELEKONSUL' ? {
            teleconsult: true,
            ...(data.DETAIL_PASIEN.SMS_STATUS ? {smsNotif: data.DETAIL_PASIEN.SMS} : {}),
            ...(data.DETAIL_PASIEN.EMAIL_STATUS ? {emailNotif: data.DETAIL_PASIEN.EMAIL} : {}),
            ...(isHavePermission ? {overrideTarif: parseInt(data.SELECTED_SLOT.price)} : {})
        } : {}
        return {
            ...detailPasien,
            "tanggalKunjungan": data.DATE,
            "pesan": data.MESSAGE,
            "jadwal": {
              "dokter": {
                "id": data.SELECTED_SLOT.id
              }
            },
            "jenisPenjamin": {
              "nama": data.SELECTED_PENJAMIN.id
            },
            "jamMulai": data.SELECTED_SLOT.jamMulai,
            "jamSelesai": data.SELECTED_SLOT.jamSelesai,
           ...modeTelekonsul,
            // ...(data.overrideTarif || data.teleconsult ? {"overrideTarif": parseFloat(data.overrideTarif)} : {})}
        }
    },

    payloadUpdateReservasi(data ,generatedId, isHavePermission) {
        const detailPasien = data.MODE == 'DETAIL_PASIEN' ? {
            email: data.SELECTED_PASIEN.email,
            gender: data.SELECTED_PASIEN.jenisKelamin,
            namaPasien: data.SELECTED_PASIEN.nama,
            nomorMedicalRecord: data.SELECTED_PASIEN.noMedicalRecord ?? null,
            patientId: data.SELECTED_PASIEN.id,
            tanggalLahir: data.SELECTED_PASIEN.tanggalLahir,
            telp: data.SELECTED_PASIEN.noTelp,

        } : {
            email: data.FORM.EMAIL,
            gender: data.FORM.JENIS_KELAMIN,
            namaPasien: data.FORM.NAMA,
            nomorMedicalRecord: data.FORM.NOMOR_MRN ?  data.FORM.NOMOR_MRN : null,
            tanggalLahir: data.FORM.TANGGAL_LAHIR,
            telp: data.FORM.NO_TELP,
        }

        const modeTelekonsul = data.MODE_KONSULTASI == 'TELEKONSUL' ? {
            teleconsult: true,
            ...(data.DETAIL_PASIEN.SMS_STATUS ? {smsNotif: data.DETAIL_PASIEN.SMS} : {}),
            ...(data.DETAIL_PASIEN.EMAIL_STATUS ? {emailNotif: data.DETAIL_PASIEN.EMAIL} : {}),
            ...(isHavePermission ? {overrideTarif: parseInt(data.SELECTED_SLOT.price)} : {})
        } : {}


        return {
            // customMessage: 1,
            ...detailPasien,
            ...modeTelekonsul,
            dokterId: data.SELECTED_SLOT.id,
            ...(data.emailNotif ? {emailNotif: data.emailNotif, email: data.emailNotif} : {}),
            "generatedId": generatedId,
            "jamMulai": data.SELECTED_SLOT.jamMulai,
            "jamSelesai": data.SELECTED_SLOT.jamSelesai,
            "jenisPenjamin": data.SELECTED_PENJAMIN.id,
            "pesan": data.MESSAGE,
            tanggalKunjungan: data.DATE
        }
    }
 }
