<template>
  <div>
    <div v-for="(doctor, index) in caseData.SLOT_WITH_SELECTED" :key="generateUniqueDoctorKey(doctor, index)" class="card card-style">
      <div class="card-header d-flex justify-content-between align-items-center">
        <p class="text-start mb-0">{{ doctor.dokter }}</p>
        <p v-if="caseData.MODE_KONSULTASI === 'TELEKONSUL'" class="price mb-0">{{ formatPrice(doctor.PRICE) }}</p>
      </div>
      <div v-if="doctor.IS_LOADING_RESERVASI" class="d-flex flex-row align-items-start p-2">
          <div class="spinner-border text-primary mt-2 ml-2" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <p class="text-primary ml-4 mt-2">Loading...</p>
        </div>
        <template v-if="doctor.STATUS_JADWAL">
          <template v-if="caseData.MODE_KONSULTASI === 'TELEKONSUL' && !doctor.MODE_TELEKONSUL">
            <div class="card-body">
              <p class="text-status">Dokter tidak melayani telekonsultasi</p>
            </div>
          </template>
          <template v-if="doctor.HOLIDAY">
            <div class="card-body">
              <p class="text-status">Hari libur ({{ doctor.HOLIDAY_REMARK }})</p>
            </div>
          </template>
          <template v-else>
            <div v-for="(schedule, scheduleIndex) in doctor.jadwals" :key="generateUniqueScheduleKey(doctor, schedule, scheduleIndex )" class="card-body">
              <p class="time">{{ schedule.textJadwal }}</p>
              <div class="d-flex flex-wrap">
                <button class="mb-2 btn" v-for="(slot, slotIndex) in schedule.slots" :key="generateUniqueSlotKey(doctor, schedule, slot, slotIndex)"
                :class="{
                      'btn-outline-primary' : slot.type === 'EMPTY',
                      'btn-disabled' : slot.type === 'RESERVED',
                      'btn-selected' : isSelectedSlot(doctor, slot),
                      'btn-blocked'  : slot.type === 'BLOCKED'
                  }"
                    :style="{
                      cursor: slot.type === 'EMPTY' ? 'pointer' : 'not-allowed',
                      textDecoration: slot.type === 'BLOCKED' ? 'line-through' : 'none'
                      }"
                    :disabled="slot.type !== 'EMPTY'"
                    @click="selectSlot(doctor , schedule, slot)"
                  >
                    <template v-if="slot.type === 'BLOCKED'">
                    <span class="blocked-text">{{ slot.text }}</span>
                    </template>
                    <template v-else>
                    {{ slot.text }}
                    </template>
                  </button>
                </div>
              </div>
            </template>
          </template>
          <template v-if="!doctor.STATUS_JADWAL && doctor.HOLIDAY">
            <div class="custom-padding">
              <p class="text-status no-margin">Hari libur ({{ doctor.HOLIDAY_REMARK }})</p>
            </div>
          </template>
          <template v-if="!doctor.STATUS_JADWAL && !doctor.IS_LOADING_RESERVASI">
            <div class="card-body">
              <p class="text-status">Dokter tidak memiliki jadwal</p>
            </div>
          </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['caseData', 'selectedSlot'],
  methods: {
    selectSlot(doctor, schedule, slot) {
      if (slot.type === 'EMPTY' && !slot.value.text) {
        this.selectedSlot({
            id: doctor.id,
            jamMulai: slot.value.jamMulai,
            jamSelesai: slot.value.jamSelesai,
            nama: doctor.dokter,
            ...(doctor.MODE_TELEKONSUL ? {price: doctor.PRICE} : {})
        })
      }
    },
    formatPrice(price) {
      if(price == null || price == undefined) {
        return 'Rp -'
      }
      return `Rp ${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    },
    generateUniqueDoctorKey(doctor, index) {
      return `key-${index}-${doctor.nama}`;
    },
    generateUniqueScheduleKey(doctor, schedule, index) {
      return `schedule-${doctor.nama}-${schedule.textJadwal}-${index}`;
    },
    generateUniqueSlotKey(doctor, schedule, slot, index) {
      return `slot-${doctor.nama}-${schedule.textJadwal}-${slot.value.jamMulai ?? 'x'}-${slot.value.jamSelesai ?? 'x'}-${index}`;
    }
  },
  computed: {
    isSelectedSlot() {
      return (doctor, slot) => {
        return this.caseData.SELECTED_SLOT &&
               this.caseData.SELECTED_SLOT.id === doctor.id &&
               this.caseData.SELECTED_SLOT.nama === doctor.dokter &&
               this.caseData.SELECTED_SLOT.jamMulai === slot.value.jamMulai &&
               this.caseData.SELECTED_SLOT.jamSelesai === slot.value.jamSelesai
      }
    }
  },
}
</script>

<style scoped>

.card-style {
  box-shadow: 0px 3px 12px 0px #23415B1A;
  color: #FAFAFA;
  border-radius: 6px;
  border: none;
  margin-bottom: 2rem;
  width: 526px;
}

.card-header {
  background-color: #EFEFEF;
  height: 29px;
  padding: 0.75rem 1.25rem;
  font-size: 14px;
  color: #636363;
  font-weight: 500;
  padding-left: 11px;
  border-bottom: none;
}

.card-body {
  padding: 0.8rem;
}

.time {
  font-size: 16px;
  color: #5578EB;
  font-weight: 600;
}

.text-status {
  font-size: 14px;
  color: #636363;
  font-weight: 500;
}

.btn {
  font-size: 12px;
  border-radius: 20px;
  padding: 4px 7px;
  width: 118px;
  height: 32px;
  margin-right: 0.3rem;
}

.btn-disabled {
  color: #636363;
  background-color: #EFEFEF;
  border-color: #EFEFEF;
  cursor: not-allowed;
}

.btn-outline-primary {
  color: #5578EB;
  border-color: #5578EB;
}

.btn-outline-primary:hover,
.btn-selected {
  color: white;
  background-color: #5578EB;
  border: none;
}

.btn-blocked {
  color: #929292;
  background-color: #EFEFEF;
  border-color: #EFEFEF;
  cursor: not-allowed;
}

.btn-blocked .blocked-text {
  font-size: 400px;
  font-size: 12px;
  line-height: 14.63px;
}

.custom-padding {
    padding-left: 0.8rem;
    padding-top: 0.8rem;
}

.no-margin {
  margin: 0;
}
</style>
